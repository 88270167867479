import { defineStore } from "pinia/dist/pinia";
import { ref } from "vue";
import { getAxios } from "@/axios/axios";

export const useProfileStore = defineStore("profileStore", {
  state: () => ({
    profile: ref({}),
  }),
  getters: {
    basic(state) {
      if (this.profile.value) {
        return state.profile.value.basic;
      } else {
        this.setProfile();
      }
    },
    settlement(state) {
      if (this.profile.value) {
        return state.profile.value.settlement;
      } else {
        this.setProfile();
      }
    },
    manager(state) {
      if (this.profile.value) {
        return state.profile.value.manager;
      } else {
        this.setProfile();
      }
    },
    paymentItems(state) {
      if (this.profile.value) {
        return state.profile.value.paymentItems;
      } else {
        this.setProfile();
      }
    },
    company(state) {
      if (this.profile.value) {
        return state.profile.value.company;
      } else {
        this.setProfile();
      }
    },
  },
  actions: {
    async setProfile() {
      await getAxios(true)
        .get("/agents/me")
        .then(res => {
          if (res.data.status === 200) {
            this.profile.value = res.data.content;
          }
        })
        .catch(ex => {
          console.error(ex.message);
        });
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "profile",
        storage: sessionStorage,
      },
    ],
  },
});
