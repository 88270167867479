<template>
  <q-scroll-area class="fit bg-light-blue-1">
    <q-list>
      <template v-for="menu in menuList" :key="menu">
        <template v-if="menu.stlmFlag.indexOf(props.stlmFlag) > -1">
          <template v-if="!menu.children">
            <q-item :to="menu.to" active-class="q-item-no-link-highlighting">
              <q-item-section avatar
                ><q-icon :name="menu.icon"
              /></q-item-section>
              <q-item-section>
                <q-item-label>{{ menu.title }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>

          <template v-else>
            <q-expansion-item :label="menu.title" :icon="menu.icon">
              <q-list class="q-pl-lg">
                <template v-for="submenu in menu.children" :key="submenu">
                  <q-item
                    :to="submenu.to"
                    v-if="submenu.stlmFlag.indexOf(props.stlmFlag) > -1"
                  >
                    <q-item-section avatar
                      ><q-icon :name="submenu.icon"
                    /></q-item-section>
                    <q-item-section>
                      <q-item-label>{{ submenu.title }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-list>
            </q-expansion-item>
          </template>
        </template>
      </template>
    </q-list>
  </q-scroll-area>
</template>
<script setup>
import { onMounted } from "vue";
const props = defineProps({ userLevel: null , stlmFlag : String});


const menuList = [
  { title: "대시보드", to: "/", icon: "dashboard", stlmFlag:['Y', 'N']},
  { title: "우대사업자 조회", to: "/company/bizsize", icon: "category", stlmFlag:['Y', 'N']},
  {
    title: "하위 대리점 관리",
    icon: "domain",
    children: [
      {
        to: "/agent/item",
        use: true,
        title: "하위 대리점 등록",
        icon: "domain_add",
        stlmFlag:['Y', 'N'],
      },
      {
        to: "/agent/list",
        title: "하위 대리점 목록",
        icon: "domain",
        use: true,
        stlmFlag:['Y', 'N'],
      },
    ],
    stlmFlag:['Y', 'N'],
  },
  {
    title: "가맹점 관리",
    icon: "store",
    children: [
      {
        to: "/merchant/applyitem",
        title: "가맹점 신청",
        icon: "add_business",
        use: true,
        stlmFlag:['Y', 'N'],
      },
      {
        to: "/merchant/applylist",
        title: "가맹점 신청 내역",
        icon: "store",
        use: true,
        stlmFlag:['Y', 'N'],
      },
      {
        to: "/merchant/list",
        title: "가맹점 목록",
        icon: "storefront",
        use: true,
        stlmFlag:['Y', 'N'],
      },
      {
        to: "/merchant/terminallist",
        use: true,
        title: "단말기 관리",
        icon: "terminal",
        stlmFlag:['Y', 'N'],
      },
    ],
    stlmFlag:['Y', 'N'],
  },
  {
    title: "매출 조회",
    icon: "assessment",
    children: [
      {
        to: "/statistics/merchants",
        use: true,
        title: "가맹점 매출 조회",
        icon: "storefront",
        stlmFlag:['Y', 'N'],
      },
    ],
    stlmFlag:['Y', 'N'],
  },
  {
    title: "정산 관리",
    icon: "calculate",
    children: [
      {
        to: "/settlement/agentsche",
        use: true,
        title: "대리점 정산예정",
        icon: "calculate",
        stlmFlag:['Y'],
      },
      {
        to: "/settlement/agentchildsche",
        use: true,
        title: "하위대리점 정산예정",
        icon: "calculate",
        stlmFlag:['Y'],
      },
      {
        to: "/settlement/agentchildhistory",
        use: true,
        title: "하위 대리점 정산내역",
        icon: "savings",
        stlmFlag:['Y'],
      },
      {
        to: "/settlement/merchantlist",
        use: true,
        title: "가맹점 정산 내역",
        icon: "calculate",
        stlmFlag:['N', 'Y'],
      },
      {
        to: "/settlement/agentscheservicelist",
        use: true,
        title: "하위 대리점 지급예정 조회",
        icon: "calculate",
        stlmFlag:['N', 'Y'],
      },
      {
        to: "/settlement/agentdpstscheservicelist",
        use: true,
        title: "대리점 입금예정 조회",
        icon: "calculate",
        stlmFlag:['N'],
      },
    ],
    stlmFlag:['Y', 'N'],
  },
  {
    title: "결제 관리",
    icon: "payment",
    children: [
      {
        to: "/history/cardhistory",
        use: true,
        title: "신용카드 결제내역",
        icon: "credit_card",
        stlmFlag:['Y', 'N'],
      },
      {
        to: "/history/bankhistory",
        use: true,
        title: "계좌이체 결제내역",
        icon: "menu_book",
        stlmFlag:['Y', 'N'],
      },
      {
        to: "/history/cashhistory",
        use: true,
        title: "현금영수증 발급내역",
        icon: "receipt",
        stlmFlag:['Y', 'N'],
      },
    ],
    stlmFlag:['Y', 'N'],
  },
  {
    title: "정보 관리",
    icon: "info",
    children: [
      {
        to: "/information/mypage",
        use: true,
        title: "마이페이지",
        icon: "contacts",
        stlmFlag:['Y', 'N'],
      },
      {
        to: "/information/noticelist",
        use: true,
        title: "공지사항",
        icon: "notifications",
        stlmFlag:['Y', 'N'],
      },
    ],
    stlmFlag:['Y', 'N'],
  },
];

onMounted(() => {
  console.log(props.stlmFlag);
});
</script>
