import { defineStore } from "pinia/dist/pinia";
import { getAxios } from "@/axios/axios";
import jwtDecode from "jwt-decode";
// import { eventBus } from "@/utils/eventBus";

/**
 * 세션에 저장되는 것들
 * 1. isLoggedIn (로그인 여부)
 * 2. auth 로그인 정보 (토큰 포함한 정보)
 * 3. latestActAt (마지막 활동 시각)
 * 4. redirectUrl (로그인 후 이동할 페이지)
 */

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    isLoggedIn: sessionStorage.getItem("isLoggedIn"),
    latestActAt: sessionStorage.getItem("latestActAt"),
    auth: JSON.parse(sessionStorage.getItem("auth")),
    redirectUrl: null,
  }),
  getters: {
    // 토큰 고유값
    jti(state) {
      if (state.isLoggedIn) {
        return state.auth["jti"];
      } else {
        return null;
      }
    },
    // 엑세스토큰
    accessToken(state) {
      if (state.isLoggedIn) {
        return state.auth["access_token"];
      } else {
        return null;
      }
    },
    // 갱신토큰
    refreshToken(state) {
      if (state.isLoggedIn) {
        return state.auth["refresh_token"];
      } else {
        return null;
      }
    },
    // 토큰유형 bearer
    tokenType(state) {
      if (state.isLoggedIn) {
        return state.auth["token_type"];
      } else {
        return null;
      }
    },
    // 토큰 유효기간 (초)
    tokenExpireIn(state) {
      if (state.isLoggedIn) {
        return state.auth["expires_in"];
      } else {
        return null;
      }
    },
    // 토큰 유효기간 (~까지)
    tokenExpireAt(state) {
      const decodedToken = jwtDecode(state.auth["access_token"]);
      return decodedToken["exp"];
    },
  },
  actions: {
    async signIn(userId, password) {
      try {
        await getAxios()
          .post(
            "/auth/login",
            { userId: userId, password: password },
            {
              headers: { "Content-Type": "application/json" },
            },
          )
          .then(res => {
            if (res.data.status === 200) {
              console.log("성공");
              this.isLoggedIn = true;
              this.auth = res.data.content;
              sessionStorage.setItem("isLoggedIn", this.isLoggedIn);
              sessionStorage.setItem("auth", JSON.stringify(this.auth));
              sessionStorage.setItem(
                "latestActAt",
                String(new Date().getTime() / 1000),
              );
            } else {
              console.error("실패");
              sessionStorage.clear();
              throw new Error(res.data.message);
            }
          })
          .catch(ex => {
            console.error(ex);
            throw new Error(ex.message);
          });
      } catch (ex) {
        console.error(ex);
        throw new Error(ex.message);
      }
    },
    refreshAuth() {
      try {
        const refreshToken = this.refreshToken;
        getAxios()
          .post(
            "/auth/refresh-token",
            { refreshToken: refreshToken },
            { headers: { "Content-Type": "application/json" } },
          )
          .then(res => {
            if (res.data.status === 200) {
              console.log("성공" + res);
              this.isLoggedIn = true;
              this.auth = res.data.content;
              sessionStorage.setItem("isLoggedIn", this.isLoggedIn);
              sessionStorage.setItem("auth", JSON.stringify(this.auth));
              sessionStorage.setItem(
                "latestActAt",
                String(new Date().getTime() / 1000),
              );
            } else {
              console.error("실패");
              sessionStorage.clear();
            }
          })
          .catch(ex => {
            console.error("실패" + ex.message);
          });
      } catch (ex) {
        console.error(ex);
        return false;
      }
    },
    checkToken() {
      if (!this.isLoggedIn) {
        return false;
      }
      try {
        // 토큰이 유효한지 검사
        // 유효할경우 true
        // 유효하지 않을 경우
        //    - 갱신가능한지 (마지막 활동시간이 토큰유효기간(초) 이내이면 갱신하고 true
        //    - 만료 false
        const currentAt = Number(new Date().getTime() / 1000); // 현재시간
        const tokenExpireAt = Number(this.tokenExpireAt); // 토큰 만료 예정
        if (tokenExpireAt > currentAt) {
          // 갱신하지 않아도 됨.
          console.log("토큰갱신 불필요");
          return true;
        } else {
          const latestActAt = Number(sessionStorage.getItem("latestActAt")); // 마지막 활동시간
          const tokenExpireIn = Number(this.tokenExpireIn); // 유효기간 (초)
          const refreshAllowAt = latestActAt + tokenExpireIn;
          if (refreshAllowAt > currentAt) {
            // 갱신
            this.refreshAuth();
            return true;
          } else {
            // 자동 로그아웃
            console.log("부재중으로 자동 로그아웃");
            this.logout();
            return false;
          }
        }
      } catch (ex) {
        console.log(ex);
        this.logout();
        return false;
      }
    },
    logout() {
      sessionStorage.clear();
      this.isLoggedIn = false;
      this.latestActAt = "";
      this.auth = {};
    },
  },
});
